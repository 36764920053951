<template>
  <div style="padding:16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.condition.queryKey"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        border
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :height="height"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50"
          align="center"
        ></el-table-column>

        <!-- <el-table-column label="" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column> -->
        <!-- <el-table-column label="分类编码" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.vrCode }}</template>
          </el-table-column> -->
          <el-table-column label="vr分类名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.categoryName }}</template>
        </el-table-column>
        <el-table-column label="vr名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.vrName }}</template>
        </el-table-column>
        <el-table-column label="封面图片" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-image  style="width: 80px; height: 80px;"
              :preview-src-list="scope.row.coverImage.split(',')" :src="scope.row.coverImage">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="vr资源地址"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.vrFileUrl }}</template>
        </el-table-column>
        <!-- <el-table-column label="是否可见(0:否;1:是)" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.isVisible }}</template>
          </el-table-column> -->
        <el-table-column label="排序" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.seq }}</template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDate }}</template>
        </el-table-column>
        <!-- <el-table-column label="创建人编码" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createBy }}</template>
          </el-table-column> -->
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small"
              >编辑</el-button
            >
            <el-popconfirm
              title="确定删除吗？"
              @confirm="handleDelte(scope.row.vrCode)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="labelType == 'add' ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="800px"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" :key="timer">
        <el-row :gutter="10">
          <!-- <el-col :span="12">
              <el-form-item label="：" prop="siteName">
                <el-input v-model="form.id" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="分类编码：" prop="siteName">
                <el-input v-model="form.vrCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="vr名称：" prop="siteName">
              <el-input
                v-model="form.vrName"
                placeholder="请输入vr名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="vr资源地址：" prop="siteName">
              <el-input
                v-model="form.vrFileUrl"
                placeholder="请输入vr资源地址"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
              <el-form-item label="是否可见(0:否;1:是)：" prop="siteName">
                <el-input v-model="form.isVisible" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="排序：" prop="siteName">
              <el-input v-model="form.seq" placeholder="请输入排序"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!form.itemCode">
             <el-form-item label="选择分类：" prop="vrCatogary">
                 <el-select v-model="form.categoryCode" placeholder="请选择">
                  <el-option
                    v-for="item in vrCatogaryList"
                    :key="item.categoryCode"
                    :label="item.categoryName"
                    :value="item.categoryCode">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面图片：" prop="coverImage">
              <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="false"
                :on-success="handleSuccess">
                <img v-show="form.coverImage" :src="form.coverImage" class="avatar">
                <div v-show="!form.coverImage">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelType: "add",
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      form: {
        vrName: "", // 分类名称
        vrFileUrl: "", // 资源地址
        seq: "", // 排序
        coverImage: "" // 封面图片
      },
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
      timer:'',
      vrCatogaryList:[],
    };
  },

  created() {
    this.getList();
    this.getCatogary()
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 256;
    },
    //查询分类
    getCatogary(){
      const data = {}
      this.$http.post('/vrCategory/queryList',data).then(res => {
        if(res.data.code == 200) {
            this.vrCatogaryList = res.data.data
         }
      })
    },
    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/vrManage/list", that.queryInfo)
        .then(function(response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
            // that.$notify.success({
            //   title: "提示",
            //   message: "获取数据成功",
            //   showClose: true,
            // });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 保存
    handleSave() {
      var that = this;
      that.$http
        .post("/vrManage/save", that.form)
        .then(function(response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true
            });
            that.showDialog = false;
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true
            });
          }
        })
        .catch(function(error) {});
    },

    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/vrManage/delete", { vrCode: id })
          .then(function(response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true
              });
              that.getList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true
              });
            }
          });
      }
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },

    // 覆盖原来的上传行为
    handleSuccess(res, file) {
      this.form.coverImage = res.data.url;
      this.timer = new Date().getTime()
      // console.log(this.form.coverImage);
    }
  }
};
</script>

<style scoped>
.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.vrimg{
  width: 80px;
  height: 80px;
}
</style>
